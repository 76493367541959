import React from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput
} from 'react-admin'

export const UserCreate = (props) => {
  const roles = [
    { id: 'admin', name: 'Admin' },
    { id: 'editor', name: 'Editor' },
    { id: 'contributor', name: 'Contributor' }
  ]

  function render () {
    return (
      <Create {...props}>
        <SimpleForm redirect="list">
          <TextInput source="email" type="email" />
          <TextInput source="password" type="password" />
          <SelectInput source="role" choices={roles} />
        </SimpleForm>
      </Create>
    )
  }

  return render()
}
