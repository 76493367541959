import React, { useState } from 'react'
import {
  Button,
  ImageInput,
  SimpleForm
} from 'react-admin'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import { API_URL } from '../../config'
import { httpClient } from '../../services/dataProvider'
import { MediaField } from './MediaField'
import { MediaList } from './MediaList'

export const FileManagerDialog = ({
  accept,
  onClose,
  onSelect,
  record,
  tag,
  ...props
}) => {
  const [fileInput, setFileInput] = useState(null)
  const [isUploading, setUploading] = useState(false)

  async function handleUpload () {
    const form = new FormData()

    form.append('file', fileInput)
    form.append('tag', tag)

    try {
      setUploading(true)

      const response = await httpClient(`${API_URL}/upload`, { method: 'POST', body: form })

      setFileInput(null)

      const { url } = response.json

      setFileAndCloseDialog(url)

      setUploading(false)
    } catch (error) {
      setUploading(false)
      console.error(error)
    }
  }

  function setFileAndCloseDialog (url) {
    onSelect(url)
    onClose()
  }

  function handleChangeFile (file) {
    setFileInput(file)
  }

  return (
    <Dialog
      {...props}
      fullWidth
    >
      <DialogTitle>Upload or browse</DialogTitle>

      <DialogContent>
        <SimpleForm {...props} toolbar={null}>
          <ImageInput source="pictures" label="" accept={accept} onChange={handleChangeFile}>
            <MediaField source="src" title="title" file={fileInput} />
          </ImageInput>

          {
            !isUploading
              ? (<Button color="primary" variant="contained" label="Upload" onClick={handleUpload} />)
              : (<Button color="primary" variant="contained" label="Uploading ..." disabled={true} />)
          }

        </SimpleForm>

        <MediaList onSelect={setFileAndCloseDialog} tag={tag} />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose}><CancelIcon/></Button>
      </DialogActions>
    </Dialog>
  )
}
