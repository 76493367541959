import React from 'react'
import {
  Create,
  DateTimeInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin'

export const QuizCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm redirect="list">
        <TextInput source="school_name" resettable />
        <TextInput source="class_name" resettable />
        <DateTimeInput source="scheduled_at" resettable />
        <ReferenceInput label="Template" source="template_id" reference="templates">
          <SelectInput optionText="name" />
        </ReferenceInput>
      </SimpleForm>
    </Create>
  )
}
