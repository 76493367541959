import React, { Fragment, useState } from 'react'
import { Button } from 'react-admin'
import { Typography } from '@material-ui/core'
import CloudUpload from '@material-ui/icons/CloudUpload'
import { FileManagerDialog } from './FileManagerDialog'

export const FileManagerButton = ({ onSelect, className, basePath, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  function toggleOpen () {
    setIsOpen(!isOpen)
  }

  return (
    <Fragment>
      <Typography style={{ marginTop: '-20px', marginBottom: '10px' }}>
        <Button
          color="default"
          variant="contained"
          label="Upload"
          startIcon={<CloudUpload/>}
          onClick={toggleOpen}
        />
      </Typography>

      <FileManagerDialog
        {...props}
        open={isOpen}
        onClose={toggleOpen}
        onSelect={onSelect}
      />
    </Fragment>
  )
}
