import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { TemplateQuestions } from './components/TemplateQuestions'
import './TemplateEdit.css'
import { AttachQuestionButton } from './components/AttachQuestionButton'
import { CustomToolbar } from '../../components/CustomToolbar'
import { FileManagerInput } from '../../components/FileManager/FileManagerInput'

export const TemplateEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm
        redirect="edit"
        submitOnEnter={false}
        warnWhenUnsavedChanges
        toolbar={<CustomToolbar/>}
      >
        <TextInput source="name" resettable />

        <TemplateQuestions label="Questions" fullWidth />

        <AttachQuestionButton />

        <FileManagerInput source="intro_video" accept="video/*" resettable fullWidth />
      </SimpleForm>
    </Edit>
  )
}
