import React from 'react'
import { useDataProvider, useRefresh, useNotify, Button } from 'react-admin'

import {
  KeyboardArrowUp as UpIcon,
  KeyboardArrowDown as DownIcon
} from '@material-ui/icons'

export const PositionField = ({ ids }, record) => {
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  function move(array, from, to) {
    if(to === from) return array

    var target = array[from]
    var increment = to < from ? -1 : 1

    for(var k = from; k !== to; k += increment){
      array[k] = array[k + increment]
    }

    array[to] = target

    return array
  }

  function handlePositionUp () {
    const currentIndex = ids.indexOf(record.id)

    const newIds = move(ids, currentIndex, currentIndex-1)

    update(newIds)
  }

  function handlePositionDown () {
    const currentIndex = ids.indexOf(record.id)

    const newIds = move(ids, currentIndex, currentIndex+1)

    update(newIds)
  }

  async function update (ids ) {
    const { template_id } = record.pivot

    await dataProvider.update('templatequestions', { id: template_id, data: { ids } })

    notify('Order updated')

    refresh()
  }

  return (
    <div className="arrow-container">
      <Button onClick={handlePositionUp} className="up-arrow"><UpIcon/></Button>
      <Button onClick={handlePositionDown} className="down-arrow"><DownIcon/></Button>
    </div>
  )
}
