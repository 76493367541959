import React from 'react'
import { Link } from 'react-router-dom'
import { Button } from 'react-admin'
import { Add as AddIcon } from '@material-ui/icons'
import { Typography } from '@material-ui/core'

export const AddAnswerButton = ({ record }) => {
  function createAnswerLink () {
    return {
      pathname: '/answers/create',
      state: { question_id: record.id }
    }
  }

  return (
    <Typography align="right">
      <Button
        color="secondary"
        variant="contained"
        label="Add answer"
        component={Link}
        startIcon={<AddIcon/>}
        to={createAnswerLink()}
      />
    </Typography>
  )
}
