import React, { useState } from 'react'
import { useInput } from 'react-admin'
import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

export const SliderInput = (props) => {
  const { input: { onChange, value } } = useInput(props)

  const [time, setTime] = useState(value)

  function onSliderChange (newValue) {
    setTime(newValue)
  }

  function onAfterChange (newValue) {
    onSliderChange(newValue)
    onChange(newValue)
  }

  return (
    <div className={props.className} style={{ width: 256 }}>
      <span className="slider-title">{time} sec</span>
      <Slider
        min={props.min}
        max={props.max}
        value={time}
        onChange={onSliderChange}
        onAfterChange={onAfterChange}
      />
    </div>
  )
}
