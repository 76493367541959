import React from 'react'
import { Admin, Resource } from 'react-admin'
import { createMuiTheme } from '@material-ui/core/styles'
import pink from '@material-ui/core/colors/pink';
import { dataProvider } from './services/dataProvider'
import { authProvider } from './services/authProvider'
// resources
import { AnswersResource } from './resources/answers'
import { BranchesResource } from './resources/branches'
import { PlayersResource } from './resources/players'
import { QuestionsResource } from './resources/questions'
import { QuizzesResource } from './resources/quizzes'
import { ResultsResource } from './resources/results'
import { TemplatesResource } from './resources/templates'
import { UsersResource } from './resources/users'
import { ZonesResource } from './resources/zones'
import { loginPage, AuthResource } from './resources/auth'

/**
 * ICONS
 * https://material-ui.com/components/material-icons/
 */

const theme = createMuiTheme({
  palette: {
    secondary: pink
  }
})

const appConfig = {
  theme,
  dataProvider,
  authProvider,
  loginPage
}

const App = () => (
  <Admin {...appConfig}>
    <Resource name="quizzes" {...QuizzesResource} />
    <Resource name="results" {...ResultsResource} />
    <Resource name="players" {...PlayersResource} />
    <Resource name="questions" {...QuestionsResource} />
    <Resource name="templates" {...TemplatesResource} />
    <Resource name="answers" {...AnswersResource} />
    <Resource name="branches" {...BranchesResource} />
    <Resource name="2fa" {...AuthResource} />
    <Resource name="users" {...UsersResource} />
    <Resource name="templatequestions" />
    <Resource name="game_states" />
    <Resource name="zones" {...ZonesResource} />
  </Admin>
)

export default App
