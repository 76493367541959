import React, { useEffect } from 'react'
import { Create, SimpleForm, TextInput, BooleanInput } from 'react-admin'
import { useHistory } from 'react-router-dom'

export const AnswerCreate = (props) => {
  const history = useHistory()

  useEffect(() => {
    const { question_id } = initialValues()

    if (question_id === null) {
      history.push('/questions')
    }
  })

  function initialValues () {
    const { state = {} } = props.location

    const { question_id = null } = state

    return { question_id }
  }

  function redirect () {
    const { question_id } = initialValues()

    return {
      pathname: `/questions/${question_id}`
    }
  }

  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect()}
        initialValues={initialValues()}
      >
        <BooleanInput source="correct" />
        <TextInput source="en" label="Answer" resettable fullWidth />
      </SimpleForm>
    </Create>
  )
}
