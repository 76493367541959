import { API_URL } from '../config'

export const authProvider = {
  login: ({ username, password, code }) => {
    const request = new Request(`${API_URL}/auth/login`, {
      method: 'POST',
      body: JSON.stringify({ username, password, code }),
      headers: new Headers({ 'Content-Type': 'application/json' })
    })

    return fetch(request)
      .then((response) => {
        if (response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText)
        }

        return response.json()
      })
      .then(({ token }) => {
        localStorage.setItem('token', token)
      })
  },
  logout: () => {
    localStorage.removeItem('token')

    return Promise.resolve()
  },
  checkAuth: () => localStorage.getItem('token')
    ? Promise.resolve()
    : Promise.reject(),
  checkError: (error) => {
    const status = error.status

    if (status === 401 || status === 403) {
        localStorage.removeItem('token')

        return Promise.reject()
    }

    return Promise.resolve()
  },
  getPermissions: async () => {
    const token = localStorage.getItem('token')

    const headers = new Headers({
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    })

    const request = new Request(`${API_URL}/auth/permissions`, {
      method: 'GET',
      headers
    })

    try {
      const response = await fetch(request)

      const data = await response.json()

      return data.role
    } catch (error) {
      return null
    }
  }
}
