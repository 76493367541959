import ColorLensIcon from '@material-ui/icons/ColorLens'

import { TemplateCreate } from './TemplateCreate'
import { TemplateEdit } from './TemplateEdit'
import { TemplateList } from './TemplateList'

export const TemplatesResource = {
  icon: ColorLensIcon,
  create: TemplateCreate,
  edit: TemplateEdit,
  list: TemplateList
}
