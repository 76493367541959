import React from 'react'
import MovieIcon from '@material-ui/icons/Movie'

export const MediaField = ({ className, file }) => {
  function render () {
    if (file === null) return ''

    const isImage = `${file.type}`.startsWith('image')

    const mediaElement = isImage
      ? <img src={URL.createObjectURL(file)} alt="preview" style={{ maxHeight: '150px', maxWidth: '150px' }}/>
      : <div style={{ height: '50px', display: 'flex', alignItems: 'start' }}><MovieIcon/> {file.name}</div>

    return (
      <div className={className}>
        {mediaElement}
      </div>
    )
  }

  return render()
}
