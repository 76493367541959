import React from 'react'
import { ReferenceManyField, Datagrid, TextField, FunctionField } from 'react-admin'
import { PositionField } from './PositionField'
import { DetachQuestionField } from './DetachQuestionField'

const TemplateQuestionGrid = (props) => {
  function renderQuestionId ({ id }) {
    const path = `/#/questions/${id}`

    return (
      <a href={path}>{id}</a>
    )
  }

  return (
    <Datagrid {...props}>
      <TextField source="pivot.position" label="Position" sortable={false} />
      <FunctionField label="id" sortable={false} render={renderQuestionId} />
      <TextField source="en" label="Question" sortable={false} />
      <FunctionField render={(record) => PositionField(props, record)} sortable={false} />
      <DetachQuestionField />
    </Datagrid>
  )
}

export const TemplateQuestions = (props) => {
  return (
    <ReferenceManyField
      {...props}
      reference="templatequestions"
      target="template_id"
      sort={{ field: 'pivot.position', order: 'ASC' }}
    >
      <TemplateQuestionGrid />
    </ReferenceManyField>
  )
}
