import { TwoFactorConfig } from './TwoFactorConfig'
import PhonelinkLockIcon from '@material-ui/icons/PhonelinkLock'
export { LoginPage as loginPage } from './Login'


export const AuthResource = {
  icon: PhonelinkLockIcon,
  options: { label: '2FA' },
  list: TwoFactorConfig
}
