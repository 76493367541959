import React from 'react'
import {
  DeleteButton,
  SaveButton,
  Toolbar
} from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between'
  }
})

export const CustomToolbar = (props) => {
  const styles = useStyles()

  function render () {
    return (
      <Toolbar {...props} classes={styles}>
        <SaveButton />
        <DeleteButton undoable={false} />
      </Toolbar>
    )
  }

  return render()
}
