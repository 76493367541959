import React, {
  Component,
  createRef,
  Fragment
} from 'react'
import { dataProvider } from '../services/dataProvider'

export class HitTheSpot extends Component {
  constructor (props) {
    super(props)

    this.isLoaded = false

    this.canvasRef = createRef()

    this.canvasSettings = {
      width: 400,
      height: 400,
      ref: this.canvasRef
    }

    this.radius = 30

    this.ctx = null

    this.imgEl = new Image()
  }

  componentDidUpdate () {
    const { image } = this.props

    const canvas = this.canvasRef.current

    if (this.isLoaded === false && canvas && image) {
      this.isLoaded = true

      this.ctx = canvas.getContext('2d')

      this.loadImage()
    }
  }

  loadImage () {
    this.imgEl.onload = () => {
      this.drawImage()

      const { coords } = this.props

      if (coords) {
        const { x, y } = coords

        this.drawAnswerArea({ x, y })
      }
    }

    this.imgEl.src = this.props.image
  }

  drawImage () {
    const { width, height } = this.canvasSettings

    this.ctx.clearRect(0, 0, width, height)

    this.ctx.drawImage(this.imgEl, 0, 0, width, height)
  }

  drawAnswerArea ({ x, y }) {
    if (x !== null && y !== null) {
      this.ctx.beginPath()
      this.ctx.arc(x, y, this.radius, 0, 2 * Math.PI)
      this.ctx.fillStyle = 'rgba(0, 255, 0, 0.5)'
      this.ctx.fill()
      this.ctx.stroke()
      this.ctx.closePath()
    }
  }

  handleClick (event) {
    const canvas = this.canvasRef.current

    const rect = canvas.getBoundingClientRect()

    const x = event.clientX - rect.left
    const y = event.clientY - rect.top

    this.storeAnswer({ x, y })
  }

  async storeAnswer ({ x, y }) {
    const { answer: answerId } = this.props

    if (answerId) {
      await dataProvider.update('answers', { id: this.props.answer, data: { x, y } })
    }

    this.drawImage()
    this.drawAnswerArea({ x, y })
  }

  render () {
    if (!this.props.visible) {
      return null
    } else if (!this.props.image) {
      return (
        <Fragment>
          <hr/>
          <div>You must set the <b>question image</b> first</div>
        </Fragment>
      )
    } else {
      return (
        <Fragment>
          <hr/>
          <canvas {...this.canvasSettings} onClick={this.handleClick.bind(this)} />
        </Fragment>
      )
    }
  }
}
