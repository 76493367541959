import React, { Fragment } from 'react'
import {
  BooleanInput,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  FormTab,
  ReferenceInput,
  SelectInput,
  TabbedForm,
  TextInput
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { GameStates } from './components/GameStates'
import { CustomToolbar } from '../../components/CustomToolbar'

export const QuizEdit = ({ permissions, ...props }) => {
  return (
    <Edit {...props}>
      <TabbedForm
        redirect="edit"
        submitOnEnter={false}
        warnWhenUnsavedChanges
        toolbar={<CustomToolbar/>}
      >
        <FormTab label="Summary">
          <FormDataConsumer>
            {
              () => {
                return (
                  <Fragment>
                    {
                      ['admin', 'editor'].includes(permissions) && <BooleanInput source="active"/>
                    }
                    <Grid container spacing={3} fullWidth>
                      <Grid item xs={3}>
                        <div>
                          <TextInput source="code" resettable fullWidth />
                        </div>
                        <div>
                          <ReferenceInput label="Template" source="template_id" reference="templates" fullWidth>
                            <SelectInput optionText="name" />
                          </ReferenceInput>
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div>
                          <TextInput source="school_name" resettable fullWidth />
                        </div>
                        <div>
                          <TextInput source="class_name" resettable fullWidth />
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div>
                          <DateTimeInput source="scheduled_at" resettable fullWidth />
                        </div>
                      </Grid>
                    </Grid>
                  </Fragment>
                )
              }
            }
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Game State">
          <GameStates fullWidth />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}
