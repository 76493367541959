import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  DeleteButton
} from 'react-admin'

export const UserList = ({ permissions, ...props }) => {
  function render () {
    if (permissions !== 'admin') {
      return 'You are not allowed to access this area'
    }

    return (
      <List {...props}
        sort={{ field: 'email', order: 'DESC' }}
        exporter={false}
      >
        <Datagrid optimized>
          <TextField source="id" />
          <TextField source="email" />
          <BooleanField source="twofactor_enabled" />
          <TextField source="role" />
          <DeleteButton undoable={false} />
        </Datagrid>
      </List>
    )
  }

  return render()
}
