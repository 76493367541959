import SportsEsportsIcon from '@material-ui/icons/SportsEsports'

import { QuizCreate } from './QuizCreate'
import { QuizEdit } from './QuizEdit'
import { QuizList } from './QuizList'

export const QuizzesResource = {
  icon: SportsEsportsIcon,
  create: QuizCreate,
  edit: QuizEdit,
  list: QuizList
}
