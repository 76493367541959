import React from 'react'
import {
  Datagrid,
  DeleteButton,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput
} from 'react-admin'

const PlayerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Player name" source="name" />
  </Filter>
)

export const PlayerList = ({ permissions, ...props }) => {
  function render () {
    if (permissions !== 'admin') {
      return 'You are not allowed to access this area'
    }

    return (
      <List {...props}
        sort={{ field: 'id', order: 'DESC' }}
        filters={<PlayerFilter />}
        exporter={false}
      >
        <Datagrid optimized>
          <TextField source="id" />
          <ReferenceField label="Quiz" source="quiz_id" reference="quizzes">
            <TextField source="code" />
          </ReferenceField>
          <TextField source="name" />
          <DeleteButton undoable={false} />
        </Datagrid>
      </List>
    )
  }

  return render()
}
