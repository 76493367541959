import React, { Fragment, useState, useEffect } from 'react'
import {
  Button, SimpleForm, Datagrid, TextField, fetchStart, fetchEnd,
  useDataProvider, FunctionField, useNotify, useRefresh } from 'react-admin'
import { Typography, Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core'
import {
  Add as AddIcon,
  AddCircle as AddCircleIcon,
  Cancel as CancelIcon
} from '@material-ui/icons'

const AttachButton = ({ record: { id: question_id }, template_id, props }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  async function attachQuestion () {
    await dataProvider.update('templatequestions', { id: template_id, data: { attach: true, question_id } })

    notify('Question attached to this template')

    props.onClose()

    refresh()
  }

  return (
    <Button
      label="Attach"
      variant="outlined"
      onClick={attachQuestion}
    >
      <AddCircleIcon/>
    </Button>
  )
}

const AttachQuestionDialog = ({ record: { id }, ...props }) => {
  const dataProvider = useDataProvider()

  const [data, setData] = useState({})

  useEffect(() => {
    async function fetchData() {
      fetchStart()

      setData({})

      const { data: questionsNotInTemplate } = await dataProvider.getOne('templatequestions', { id })

      setData(questionsNotInTemplate.reduce((questions, question) => {
        return {
          ...questions,
          [question.id]: question
        }
      }, {}))

      fetchEnd()
    }

    if (props.open) fetchData()
  }, [props.open, id, dataProvider])

  function ids () {
    return Object.keys(data)
  }

  return (
    <Dialog
      {...props}
      fullWidth
    >
      <DialogTitle>Attach question</DialogTitle>

      <DialogContent>
        <SimpleForm {...props} toolbar={null}>
          <Datagrid {...props} data={data} ids={ids()} currentSort={{}} fullWidth>
            <TextField source="id" sortable={false} />
            <TextField source="en" label="Question" sortable={false} />
            <FunctionField render={(record) => AttachButton({ props, record, template_id: id }) } sortable={false} />
          </Datagrid>
        </SimpleForm>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.onClose}><CancelIcon/></Button>
      </DialogActions>
    </Dialog>
  )
}

export const AttachQuestionButton = ({ className, basePath, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)

  function toggleOpen () {
    setIsOpen(!isOpen)
  }

  return (
    <Fragment>
      <Typography align="right" style={{ marginTop: '10px' }}>
        <Button
          color="secondary"
          variant="contained"
          label="Attach question"
          startIcon={<AddIcon/>}
          onClick={toggleOpen}
        />
      </Typography>

      <AttachQuestionDialog
        {...props}
        open={isOpen}
        onClose={toggleOpen}
      />
    </Fragment>
  )
}
