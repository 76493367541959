import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'

import { QuestionCreate } from './QuestionCreate'
import { QuestionEdit } from './QuestionEdit'
import { QuestionList } from './QuestionList'

export const QuestionsResource = {
  icon: QuestionAnswerIcon,
  create: QuestionCreate,
  edit: QuestionEdit,
  list: QuestionList
}
