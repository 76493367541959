import React from 'react'
import { APP_URL } from '../../../config'

export const AppPreview = ({ id }) => {
  function getPreviewURL () {
    const url = new URL(`${APP_URL}/preview`)

    const data = {
      id,
      n: new Date()
    }

    const hash = btoa(unescape(encodeURIComponent(JSON.stringify(data))))

    url.searchParams.append('hash', hash)

    return url
  }

  function render () {
    return (
      <>
        <iframe
          title="app-preview"
          width="375px"
          height="667px"
          src={getPreviewURL()}
          style={{ border: 'none', flex: 1, overflowY: 'visible', backgroundColor: '#eee', padding: '30px 10px 0 10px', borderRadius: '15px 15px 0 0' }}
        />
        <div style={{ backgroundColor: '#eee', width: '375px', textAlign: 'center', padding: '15px 10px 15px 10px', borderRadius: '0 0 15px 15px'  }}>
          <span style={{ height: '30px', width: '30px', backgroundColor: '#bbb', borderRadius: '50%', display: 'inline-block' }}></span>
        </div>
      </>
    )
  }

  return render()
}
