import React from 'react'
import { Button, useDataProvider, useNotify, useRefresh } from 'react-admin'
import { RemoveCircle as RemoveCircleIcon } from '@material-ui/icons'

export const DetachQuestionField = ({ record }) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const refresh = useRefresh()

  async function detachQuestion () {
    const { question_id, template_id } = record.pivot

    await dataProvider.update('templatequestions', { id: template_id, data: { detach: true, question_id } })

    notify('Question detached from this template')

    refresh()
  }

  return (
    <Button
      label="Detach"
      variant="outlined"
      onClick={detachQuestion}
    >
      <RemoveCircleIcon/>
    </Button>
  )
}
