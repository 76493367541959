import React, { useState } from 'react'
import {
  BooleanField,
  Datagrid,
  DeleteButton,
  Edit,
  EditButton,
  NumberInput,
  ReferenceManyField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useEditController
} from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { SliderInput } from '../../components/SliderInput'
import { AddAnswerButton } from './components/AddAnswerButton'
import { FileManagerInput } from '../../components/FileManager/FileManagerInput'
import { AppPreview } from './components/AppPreview'
import { CustomToolbar } from '../../components/CustomToolbar'

export const QuestionEdit = (props) => {
  const [questionTypes] = useState([
    { id: 'text', name: 'Text' },
    { id: 'hit', name: 'Hit the Spot' },
    { id: 'images', name: 'Images' },
  ])

  const { record } = useEditController(props)

  return (
    <Edit {...props}>
      <SimpleForm
        redirect="edit"
        submitOnEnter={false}
        warnWhenUnsavedChanges
        toolbar={<CustomToolbar/>}
      >

        <NumberInput source="floor" />

        <TextInput source="en" label="Question" resettable fullWidth />

        <SelectInput source="type" choices={questionTypes} />

        <SliderInput source="time" min={5} max={40} addLabel="true" />

        <TextInput source="theme" />

        <FileManagerInput source="icon" accept="image/*" resettable fullWidth />

        <FileManagerInput source="info_image" accept="image/*" resettable fullWidth />

        <TextInput source="info_text" resettable fullWidth multiline />

        <FileManagerInput source="video" accept="video/*" resettable fullWidth />

        <TextInput source="video_caption" resettable fullWidth />

        <FileManagerInput source="image" accept="image/*" resettable fullWidth />

        <ReferenceManyField
          label="Answers"
          target="question_id"
          reference="answers"
          fullWidth
          sort={{ field: 'id', order: 'ASC' }}
        >
          <Datagrid>
            <BooleanField source="correct" />

            <TextField source="en" label="answer" />

            <EditButton />

            <DeleteButton redirect="" undoable={false}/>

          </Datagrid>
        </ReferenceManyField>

        <AddAnswerButton />

        <TextInput source="correct_text" label="Correct Answer Text" resettable fullWidth />

        <TextInput source="wrong_text" label="Wrong Answer Text" resettable fullWidth />

        <Grid container spacing={3} fullWidth>
          <Grid item xs={6}>
            <FileManagerInput source="background_video" accept="video/*" resettable fullWidth />

            <FileManagerInput source="correct_background_video" accept="video/*,image/png" resettable fullWidth />

            <FileManagerInput source="wrong_background_video" accept="video/*,image/png" resettable fullWidth />
          </Grid>
          <Grid item xs={6}>
            <AppPreview id={props.id} record={record} />
          </Grid>
        </Grid>

      </SimpleForm>
    </Edit>
  )
}
