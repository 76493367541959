import React, { useState, useEffect } from 'react'
import { useRedirect } from 'react-admin'
import { authProvider } from '../../services/authProvider'
import './login.css'

export function LoginPage () {
  const redirect = useRedirect()

  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [code, setCode] = useState('')

  useEffect(() => {
    async function checkAuth () {
      try {
        await authProvider.checkAuth()

        redirect('/')
      } catch (error) {
        // nothing
      }
    }

    checkAuth()
  })

  async function handleLogin (event) {
    event.preventDefault()

    const form = { username, password, code }

    try {
      await authProvider.login(form)

      redirect('/')
    } catch (error) {
      alert('Invalid credentials')
    }
  }

  function setField (callback) {
    return (event) => {
      callback(event.target.value)
    }
  }

  function render () {
    return (
      <div className="login">
        <div className="card">
          <div>
            <h1>BODYWORLDS CMS</h1>
          </div>
          <form onSubmit={handleLogin}>
            <div>
              <input type="email" placeholder="Username" onChange={setField(setUsername)}/>
            </div>
            <div>
              <input type="password" placeholder="Password" onChange={setField(setPassword)} />
            </div>
            <div>
              <input type="number" placeholder="2FA code" onChange={setField(setCode)} />
            </div>
            <div>
              <button type="submit">Sign In</button>
            </div>
          </form>
        </div>
      </div>
    )
  }

  return render()
}
