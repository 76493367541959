import React, { useEffect, useState } from 'react'
import { useDataProvider } from 'react-admin'

export const MediaList = ({ onSelect, tag }) => {
  const dataProvider = useDataProvider()
  const [medias, setMedias] = useState([])
  const [isLoaded, setLoaded] = useState(false)

  useEffect(() => {
    if (isLoaded) return

    (async () => {
      const { data } = await dataProvider.getList('files', {
        pagination: { page: 1, perPage: -1 },
        sort: { field: 'id', order: 'DESC' },
        filter: { tag }
      })

      setLoaded(true)

      if (data) {
        setMedias(data)
      }
    })()
  })

  function render () {
    const theMediaStyle = {
      width: '150px',
      height: '150px',
      float: 'left',
      textAlign: 'center',
      background: '#eee',
      margin: '5px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      cursor: 'pointer'
    }

    const mediaList = medias.map((media) => {
      return (
        <div style={theMediaStyle} key={`${media.id}`} onClick={() => onSelect(media.url)}>
          {
            media.type === 'image'
              ? (<img src={media.url} alt="preview" style={{ width: '100px', height: '100px', margin: '5px' }} />)
              : (<video src={media.url} width="100" height="100" muted preload="metadata" style={{ width: '100px', height: '100px', margin: '5px' }} />)
          }
          <div style={{ fontSize: '0.9rem', flex: 1 }}>{media.name}</div>
        </div>
      )
    })
    return (
      <div>
        <div><hr/></div>
        <div className="custom-label">Select from an existing one</div>
        <div style={{ width: '100%', overflowY: 'hidden', overflowX: 'auto', height: '180px', display: 'flex', flexWrap: 'wrap', flexDirection: 'column' }}>
          {mediaList}
        </div>
      </div>
    )
  }

  return render()
}
