import React from 'react'
import { ReferenceManyField, Datagrid, TextField, BooleanField, FunctionField } from 'react-admin'

export const GameStates = (props) => {
  function renderScore (record) {
    const { position, score } = record

    const avgScore = Math.round(score / position)

    return `${avgScore}/100`
  }

  return (
    <ReferenceManyField
      {...props}
      reference="game_states"
      target="quiz_id"
    >
      <Datagrid>
        <BooleanField source="completed" />
        <TextField source="updated_at" />
        <TextField source="user.email" label="Player" />
        <FunctionField label="Score" render={renderScore} />
      </Datagrid>
    </ReferenceManyField>
  )
}
