import jsonServerProvider from 'ra-data-json-server'
import { fetchUtils } from 'react-admin'
import { API_URL } from '../config'

export const httpClient = (url, options = {}) => {
  if (!options.headers) {
    console.log({ url })
    if (url === `${API_URL}/upload`) {
      options.headers = new Headers({ Accept: 'multipart/form-data' })
    } else {
      options.headers = new Headers({ Accept: 'application/json' })
    }
  }

  const token = window.localStorage.getItem('token')

  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`)
  }

  return fetchUtils.fetchJson(url, options)
}

export const dataProvider = jsonServerProvider(API_URL, httpClient)
