import React from 'react'
import {
  Edit,
  SimpleForm,
  BooleanInput,
  TextField,
  TextInput,
  ImageField
} from 'react-admin'

export function TwoFactorConfig (props) {
  const config = {
    id: 'config',
    resource: '2fa',
    basePath: '/2fa',
    redirect: false,
    title: '2FA',
    undoable: false,
    ...props
  }

  function render () {
    return (
      <Edit {...config}>
        <SimpleForm>
          <BooleanInput source="twofactor_enabled" />
          <TextField source="twofactor_secret" fullWidth disabled />
          <ImageField source="qr_code" title="2FA" />
          <TextInput source="code" label="Type the 6-digits code generated by your app here" fullWidth/>
        </SimpleForm>
      </Edit>
    )
  }

  return render()
}
