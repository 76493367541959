import React, {
  useEffect,
  useState
} from 'react'
import {
  BooleanInput,
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import { HitTheSpot } from '../../components/HitTheSpot'
import { dataProvider } from '../../services/dataProvider'
import { CustomToolbar } from '../../components/CustomToolbar'
import { FileManagerInput } from '../../components/FileManager/FileManagerInput'

export const AnswerEdit = (props) => {
  const [isHitTheSpotVisible, setHitTheSpotVisibility] = useState(false)
  const [coords, setCoords] = useState(null)
  const [image, setImage] = useState(null)

  const [isImageFieldVisible, setImageFieldVisibility] = useState(false)

  function redirect (basePath, id, data) {
    return `/questions/${data.question_id}`
  }

  useEffect(() => {
    async function loadAnswerInfo () {
      const { data: { question_id, x, y } } = await dataProvider.getOne('answers', { id: props.id })

      const { data: { type, image } } = await dataProvider.getOne('questions', { id: question_id })

      if (type === 'hit') {
        setHitTheSpotVisibility(true)

        if (x && y) {
          setCoords({ x, y })
        }

        if (image) {
          setImage(image)
        }
      } else if (type === 'images') {
        setImageFieldVisibility(true)
      }
    }

    loadAnswerInfo()
  }, [props.id])

  return (
    <Edit {...props}>
      <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<CustomToolbar/>}>
        <BooleanInput source="correct" />

        <TextInput source="en" label="answer" fullWidth />

        <HitTheSpot
          answer={props.id}
          visible={isHitTheSpotVisible}
          image={image}
          coords={coords}
        />

        { isImageFieldVisible && (<FileManagerInput source="image" accept="image/*" resettable fullWidth />)}

      </SimpleForm>
    </Edit>
  )
}
