import React, { useEffect } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'
import { useHistory } from 'react-router-dom'

export const ZoneCreate = (props) => {
  const history = useHistory()

  useEffect(() => {
    const { area_id } = initialValues()

    if (area_id === null) {
      history.push('/areas')
    }
  })

  function initialValues () {
    const { state = {} } = props.location

    const { area_id = null } = state

    return { area_id }
  }

  function redirect () {
    const { area_id } = initialValues()

    return {
      pathname: `/areas/${area_id}`
    }
  }

  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect()}
        initialValues={initialValues()}
      >
        <TextInput source="name" resettable fullWidth />
      </SimpleForm>
    </Create>
  )
}
