import React from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin'

export const TemplateList = (props) => {
  return (
    <List {...props}
      sort={{ field: 'id', order: 'DESC' }}
      exporter={false}
    >
      <Datagrid optimized isRowSelectable={() => false}>
        <TextField source="id" />
        <TextField source="name" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
