import React from 'react'
import { List, Datagrid, TextField, EditButton } from 'react-admin'
import { QuestionFilter } from './components/QuestionFilter'

export const QuestionList = (props) => {
  return (
    <List {...props}
      sort={{ field: 'id', order: 'DESC' }}
      filters={<QuestionFilter />}
      exporter={false}
    >
      <Datagrid optimized>
        <TextField source="id" />
        <TextField source="en" label="Question" />
        <TextField source="floor" label="Floor" />
        <TextField source="type" label="Type" />
        <EditButton />
      </Datagrid>
    </List>
  )
}
