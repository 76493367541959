import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import { CustomToolbar } from '../../components/CustomToolbar'

export const BranchEdit = (props) => {
  function redirect (basePath, id, data) {
    return `/companies/${data.company_id}`
  }

  return (
    <Edit {...props}>
      <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<CustomToolbar/>}>
        <TextInput source="slug" label="Slug" fullWidth />

        <TextInput source="name" label="Name" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
