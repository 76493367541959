import React from 'react'
import {
  BooleanField,
  Datagrid,
  DateField,
  EditButton,
  List,
  ReferenceField,
  TextField,
  useRefresh
} from 'react-admin'
import Button from '@material-ui/core/Button'
import PowerOffIcon from '@material-ui/icons/PowerOff'
import { dataProvider } from '../../services/dataProvider'

const GameOverButton = ({ record, ...props }) => {
  const refresh = useRefresh()
  async function handleClick () {
    const quiz = {
      id: record.id,
      data: { finished: true, active: false }
    }

    await dataProvider.update('quizzes', quiz)

    refresh()
  }

  return (
    <Button color="primary" onClick={handleClick}>
      <PowerOffIcon/> <span style={{ paddingLeft: '0.5em' }}>Game over</span>
    </Button>
  )
}

export const QuizList = (props) => {
  return (
    <List {...props}
      sort={{ field: 'scheduled_at', order: 'ASC' }}
      exporter={false}
    >
      <Datagrid optimized>
        <TextField source="id" />
        <BooleanField source="active" />
        <TextField source="code" />
        <TextField source="school_name" />
        <TextField source="class_name" />
        <DateField source="scheduled_at" showTime />
        <ReferenceField label="Template" source="template_id" reference="templates">
          <TextField source="name" />
        </ReferenceField>
        <TextField source="__meta__.total_players" label="Players"/>
        <EditButton />
        <GameOverButton />
      </Datagrid>
    </List>
  )
}
