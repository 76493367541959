import React from 'react'
import {
  Datagrid,
  DateField,
  List,
  TextField,
  useRefresh
} from 'react-admin'
import Button from '@material-ui/core/Button'
import PowerIcon from '@material-ui/icons/Power'
import GetAppIcon from '@material-ui/icons/GetApp'
import { dataProvider } from '../../services/dataProvider'
import { API_URL } from '../../config'

const RestartGameButton = ({ record }) => {
  const refresh = useRefresh()
  async function handleClick () {
    const quiz = {
      id: record.id,
      data: { finished: false, active: true }
    }

    await dataProvider.update('quizzes', quiz)

    refresh()
  }

  return (
    <Button color="primary" onClick={handleClick}>
      <PowerIcon/> <span style={{ paddingLeft: '0.5em' }}>Restart game</span>
    </Button>
  )
}

const DownloadButton = ({ record }) => {
  function downloadUrl () {
    const downloadURL = `${API_URL}/results/${record.id}/download`

    return downloadURL
  }

  return (
    <Button color="primary" href={downloadUrl()} target="_blank">
      <GetAppIcon/> <span style={{ paddingLeft: '0.5em' }}>Download</span>
    </Button>
  )
}

export const ResultsList = (props) => {
  return (
    <List {...props}
      sort={{ field: 'updated_at', order: 'DESC' }}
      exporter={false}
    >
      <Datagrid optimized>
        <TextField source="id" />
        <TextField source="code" />
        <DateField source="updated_at" showTime />
        <TextField source="school_name" />
        <TextField source="class_name" />
        <TextField source="__meta__.total_players" label="Players"/>
        <RestartGameButton />
        <DownloadButton />
      </Datagrid>
    </List>
  )
}
