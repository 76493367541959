import React, { Fragment } from 'react'
import { TextInput } from 'react-admin'
import { useForm } from 'react-final-form'
import { FileManagerButton } from './FileManagerButton'

export const FileManagerInput = (props) => {
  const form = useForm()
  // const [originalValue, setOriginalValue] = useState('')

  function onChange (value) {
    form.change(props.source, value)
  }

  function onSelect (value) {
    onChange(value)

    form.submit()
  }

  function onTextInputChange (event) {
    if (event && event.target) {
      onChange(event.target.value)
    }
  }

  // function format(value) {
  //   console.log('format', value)
  //   setOriginalValue(value)

  //   const chunks = `${value}`.split('/')

  //   return chunks[chunks.length - 1]
  // }

  // function parse (value) {
  //   console.log('parse', value)
  //   return originalValue
  // }

  return (
    <Fragment>
      <TextInput
        {...props}
        onChange={onTextInputChange}
      />

      <FileManagerButton
        tag={props.source}
        accept={props.accept}
        onSelect={onSelect}
      />
    </Fragment>
  )
}
