import React, { useEffect } from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'
import { useHistory } from 'react-router-dom'

export const BranchCreate = (props) => {
  const history = useHistory()

  useEffect(() => {
    const { company_id } = initialValues()

    if (company_id === null) {
      history.push('/companies')
    }
  })

  function initialValues () {
    const { state = {} } = props.location

    const { company_id = null } = state

    return { company_id }
  }

  function redirect () {
    const { company_id } = initialValues()

    return {
      pathname: `/companies/${company_id}`
    }
  }

  return (
    <Create {...props}>
      <SimpleForm
        redirect={redirect()}
        initialValues={initialValues()}
      >
        <TextInput source="slug" label="Slug" resettable fullWidth />
        <TextInput source="name" label="Name" resettable fullWidth />
      </SimpleForm>
    </Create>
  )
}
