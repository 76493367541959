import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

export const QuestionCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm  redirect="list">
        <TextInput source="en" label="Question" resettable fullWidth />
      </SimpleForm>
    </Create>
  )
}
