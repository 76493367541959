import React from 'react'
import { Create, SimpleForm, TextInput } from 'react-admin'

export const TemplateCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm  redirect="list">
        <TextInput source="name" label="Template name" resettable fullWidth />
      </SimpleForm>
    </Create>
  )
}
