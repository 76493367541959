import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'
import { CustomToolbar } from '../../components/CustomToolbar'

export const ZoneEdit = (props) => {
  function redirect (basePath, id, data) {
    return `/areas/${data.area_id}`
  }

  return (
    <Edit {...props}>
      <SimpleForm submitOnEnter={false} redirect={redirect} toolbar={<CustomToolbar/>}>
        <TextInput source="name" label="Zone name" fullWidth />
        <TextInput source="welcome_text" label="Welcome text" fullWidth />
      </SimpleForm>
    </Edit>
  )
}
